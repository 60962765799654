import React, { Component } from 'react';
import {
    Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Badge
} from 'reactstrap';
import * as Icon from 'react-bootstrap-icons';
import authService from './api-authorization/AuthorizeService';


export class Wishlist extends Component {
    static displayName = Wishlist.name;
    constructor(props) {
        super(props);
        this.state = {
            sort: 'date.desc',
            wishlist: []
        };
        this.getWishlist();
    }

    setSort = async (e, sort) => {
        e.preventDefault();

        await this.setState({
            sort: sort
        });
        await this.getWishlist();
    }

    getWishlist = async () => {
        let user = await authService.getUser();
        
        const response = await fetch(`/api/torrents/wishlist?sort=${this.state.sort}`);
        const result = await response.json();
        let items = result.map(item => {
            let image = item.posterUrl.substring(item.posterUrl.lastIndexOf('/'));
            item.smallImage = `https://image.tmdb.org/t/p/w92${image}`;
            item.largeImage = `https://image.tmdb.org/t/p/w185${image}`;
            item.dateAdded = new Date(item.dateAdded).toLocaleString();
            item.canDelete = item.userId === user.sub;
            item.addedBy = item.user.userName;
            return item;
        })
        await this.setState({
            wishlist: items
        });
    }

    render = () => (
        <div className="content">
            <div className="d-flex flex-row align-items-center mb-2">
                <div className="h4 text-plex mb-0">
                    Wishlist Items
                    <Badge pill color="plex" className="ms-2">
                        {this.state.wishlist.length}
                    </Badge>
                </div>
                <UncontrolledDropdown className="ms-auto">
                    <DropdownToggle color="link" className="p-0 d-flex align-items-center text-plex text-decoration-none"><Icon.ArrowDownUp className="me-1" /> Sort</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem active={this.state.sort === 'date.asc'} onClick={e => this.setSort(e, 'date.asc')}><Icon.SortDownAlt /> Date Added</DropdownItem>
                        <DropdownItem active={this.state.sort === 'date.desc'} onClick={e => this.setSort(e, 'date.desc')}><Icon.SortDown /> Date Added Descending</DropdownItem>
                        <DropdownItem active={this.state.sort === 'title.asc'} onClick={e => this.setSort(e, 'title.asc')}><Icon.SortAlphaDown /> Title</DropdownItem>
                        <DropdownItem active={this.state.sort === 'title.desc'} onClick={e => this.setSort(e, 'title.desc')}><Icon.SortAlphaDownAlt /> Title Descending</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <Table striped>
                <tbody>
                    {this.state.wishlist
                        ?
                        this.state.wishlist.map(item => {
                            let url = `https://www.themoviedb.org/movie/${item.tmdbId}`;
                            return (
                                <tr key={`wl-${item.id}`}>
                                    <td>
                                        <img className="d-none d-md-block" src={item.largeImage} alt={item.displayName} />
                                        <img className="d-md-none" src={item.smallImage} alt={item.displayName} />
                                    </td>
                                    <td style={{height: '1px'} }>
                                        <div className="d-flex flex-column">
                                            <div className="h4 mb-1">
                                                <a className="link-plex text-decoration-none" target="_blank" rel="noreferrer" href={url}>
                                                    {item.title}
                                                </a>
                                            </div>
                                            <div className="text-muted"><small>Added on {item.dateAdded}</small></div>
                                            <div className="lead flex-fill">{item.overview}</div>
                                            <div className="flex-fill">
                                                {item.canDelete
                                                    ? <a href="#" className="link-plex text-decoration-none flex-shrink-1">Remove from wishlist</a>
                                                    : <span>Added by {item.addedBy}</span>}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        null
                    }
                </tbody>
            </Table>
        </div>
    )
}