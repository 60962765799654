import React, { Component } from 'react';
import * as Icon from 'react-bootstrap-icons';

export default class FlyoutMenu extends Component {
    static displayName = FlyoutMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = e => {
        this.setState({ collapsed: !this.state.collapsed });
        e.preventDefault();
    }

    render() {
        return (
            this.state.collapsed ?
                <a href="#" className="flyout-toggle" onClick={this.toggle}><Icon.PlusSquareFill /></a>
                : <div>
                    <a href="#" className="flyout-toggle" onClick={this.toggle}><Icon.DashSquareFill /></a>
                    {this.props.children}
                </div>
        )
    }
}