import React, { Component } from 'react';
import {
    Table, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    FormGroup, Input, Row, Col, Label, Badge
} from 'reactstrap';
export class PlexContent extends Component {
    static displayName = PlexContent.name;

    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            isMovies: true,
            filteredTitles: []
        }
        this.titles = [];
        this.getFilteredRef = React.createRef(AbortController | null);
    }

    componentDidMount() {
        this.getTitles();
    }

    onTypeChanged = async e => {
        e.preventDefault();
        await this.setState({
            isMovies: !this.state.isMovies
        });
        this.getTitles();
    }

    updateFilter = async (e) => {
        await this.setState({ filter: e.target.value });
        this.getFilteredTitles();
    }

    getTitles = async () => {
        const response = await fetch(`api/plex/titles/${this.state.isMovies ? 'movies' : 'tv'}`);
        const data = await response.json();

        this.titles = data.titles.map(t => {
            return {
                key: t.key,
                name: t.title ?? t.name,
                summary: t.summary,
                year: t.year,
                rated: t.contentRating,
                rating: this.state.isMovies ? t.rating : 'N/A',
                audienceRating: t.audienceRating,
                dateAdded: new Date(t.dateAdded).toLocaleDateString(),
                runtime: this.state.isMovies ? t.runtime : 'N/A',
                genre: t.genre,
                resolution: t.media ? t.media.resolution : null,
                thumb: t.thumb
            };
        });

        this.getFilteredTitles();
    }

    getFilteredTitles = async () => {
        //Check to see if we need to abort the previous call
        if (this.getFilteredRef.current) {
            this.getFilteredRef.current.abort();
        }
        //Create a new AbortController and assign it to the controllerRef
        const controller = new AbortController();
        this.getFilteredRef.current = controller;

        let filteredTitles = this.state.filter && this.state.filter.length >= 2 ?  this.titles.filter(t => t.name.indexOf(this.state.filter) > -1) : this.titles;
        await this.setState({
            filteredTitles: filteredTitles
        }, () => this.getFilteredRef.current = null);
    }

    render = () => (
        <div>
            <div className = "d-flex flex-row align-items-center mb-2" >
                <div className="h4 text-plex mb-0">
                    Plex Content
                    <Badge pill color="plex" className="ms-2">
                        {this.state.filteredTitles.length} Titles
                    </Badge>
                </div>
            </div>
            <Row className="my-2 d-flex flex-row align-items-center">
                <Col xs="auto">
                    <div className="ms-2">
                        <label className="toggle " data-on="Movies" data-off="TV">
                            <input type="checkbox" name="isMovies" checked={this.state.isMovies} onChange={this.onTypeChanged} />
                            <span></span>
                        </label>
                    </div>
                </Col>
                <Col>
                    <FormGroup floating>
                        <Input type="text" value={this.state.filter} onChange={this.updateFilter} />
                        <Label>Search Filter</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-md-none">
                        <ListGroup flush>
                            {this.state.filteredTitles.map(t => {
                                let rtImage = t.rating > 6.0
                                    ? 'tomatometer-fresh.svg'
                                    : t.rating === 0
                                        ? 'tomatometer-empty.svg'
                                        : 'tomatometer-rotten.svg';
                                let audImage = t.audienceRating > 6.0
                                    ? 'aud_score-fresh.svg'
                                    : t.audienceRating === 0
                                        ? 'aud_score-empty.svg'
                                        : 'aud_score-rotten.svg';
                                return <ListGroupItem key={t.key}>
                                    <ListGroupItemHeading className="text-break">
                                        {t.name} ({t.year})
                                        
                                    </ListGroupItemHeading>
                                    
                                    <ListGroupItemText tag="div" >
                                        <div className="d-flex flex-row flex-wrap gap-1 align-items-center">
                                            <span>{t.genre}</span>
                                            •
                                            {this.state.isMovies ? 
                                                <React.Fragment>
                                                    <span>{t.runtime}</span>
                                                    •
                                                </React.Fragment>
                                                : null}
                                            
                                            <span>{t.rated}</span>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap gap-1 align-items-center">
                                            {/*<Badge pill className="me-1" color={*/}
                                            {/*    t.resolution === 'UHD'*/}
                                            {/*        ? 'plex'*/}
                                            {/*        : t.resolution === 'FHD'*/}
                                            {/*            ? 'primary'*/}
                                            {/*            : t.resolution === 'HD'*/}
                                            {/*                ? 'danger'*/}
                                            {/*                : 'secondary'}>{t.resolution}</Badge>*/}
                                            {this.state.isMovies ?
                                                <React.Fragment>
                                                    <img src={`images/${rtImage}`} alt="" className="me-2" width="16" height="16" />
                                                    {t.rating * 10}%
                                                </React.Fragment>   
                                                : null
                                            }
                                            <img src={`images/${audImage}`} alt="" width="16" height="16" />
                                            {t.audienceRating * 10}%
                                        </div>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            })}
                        </ListGroup>
                    </div>
                    <Table striped className="d-none d-md-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Year</th>
                                <th>Genre</th>
                                <th>Rated</th>
                                <th>Resolution</th>
                                <th>Rating</th>
                                <th>Audience Rating</th>
                                <th>Date Added</th>
                                <th>Runtime</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredTitles.map(t => {
                                let rtImage = t.rating > 6.0
                                    ? 'tomatometer-fresh.svg'
                                    : t.rating === 0
                                        ? 'tomatometer-empty.svg'
                                        : 'tomatometer-rotten.svg';
                                let audImage = t.audienceRating > 6.0
                                    ? 'aud_score-fresh.svg'
                                    : t.audienceRating === 0
                                        ? 'aud_score-empty.svg'
                                        : 'aud_score-rotten.svg';
                                return (
                                    <tr key={t.key}>
                                        <td className="text-break">{t.name}</td>
                                        <td>{t.year}</td>
                                        <td>{t.genre}</td>
                                        <td>{t.rated}</td>
                                        <td>{t.resolution}</td>
                                        <td>
                                            <div className="d-flex flex-row align-items-center gap-2">
                                                <img src={`images/${rtImage}`} alt="" width="16" height="16" />
                                                {t.rating * 10}%
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row align-items-center gap-2">
                                                <img src={`images/${audImage}`} alt="" className="ms-2" width="16" height="16" />
                                                {t.audienceRating * 10}%
                                            </div>
                                        </td>
                                        <td>{t.dateAdded}</td>
                                        <td>{t.runtime}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </div>
    )
}