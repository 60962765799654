import React, { Component } from "react";
import * as Icon from 'react-bootstrap-icons';
import {
    Card, CardBody, Col, Row, CardHeader, Button,
    Collapse, ButtonGroup, Spinner, Badge
} from "reactstrap";
import { Trending } from './trending';
import { v4 as uuid4 } from 'uuid'

export class TrendingList extends Component {
    static displayName = TrendingList.name;


    constructor(props) {
        super(props);
        this.state = {
            id: uuid4(),
            isWeek: true,
            items: [],
            page: 0,
            totalPages: 0,
            isOpen: true,
            hasMoreResults: false
        };

        this.trendingClicked.bind(this);
        this.toggleCollapse.bind(this);
        this.onWeekDayChanged.bind(this);
    }

    async componentDidMount() {
        this.getTrending();
    }

    componentWillUnmount() {
        
    }

    getTrending = async (isAppend) => {
        const isUpcoming = this.props.upcoming;
        const type = this.props.type ?? 'movie';
        const searchWindow = this.state.isWeek ? 'week' : 'day';
        let url = isUpcoming ? `api/tmdb/upcoming/${type}?startpage=${this.state.page+1}` : `/api/tmdb/trending/${type}/${searchWindow}?startpage=${this.state.page + 1}`;
        const response = await fetch(url);
        const data = await response.json();
        await this.setState({
            items: isAppend ? [...this.state.items, ...data.results] : data.results,
            page: data.page,
            totalPages: data.totalPages,
            hasMoreResults: data.totalPages > data.page
        });
    }

    loadMore = async e => {
        e.preventDefault();
        this.getTrending(true);
    }

    scrollTrendingItem = (e, data) => {
        //Grab the parent container
        let parent = document.getElementById(data.container);
        //Grab the children as an array
        //let children = Array.from(parent.children);
        //Get the average child width (for these they should all be the same)
        let totalWidth = 0;
        for (var i = 0; i < this.state.items.length; i++) {
            totalWidth += parent.children[i].scrollWidth;
        }
        let itemWidth = totalWidth / this.state.items.length; //children.reduce((total, node) => total + node.scrollWidth, 0) / children.length;
        //Get the distance to scroll
        let scrollDistance = Math.min(parent.offsetWidth, Math.floor(parent.offsetWidth / itemWidth) * itemWidth);
        //Scroll this bad boy
        let newPosition = data.direction === 'right'
            ? parent.scrollLeft + scrollDistance
            : parent.scrollLeft - scrollDistance;
        parent.scrollTo({ left: newPosition, behavior: 'smooth' });
    }

    trendingClicked = async (e) => {
        e.preventDefault();
        let data = JSON.parse(e.currentTarget.attributes['data-item'].value);
        if (this.props.onItemClicked) {
            this.props.onItemClicked(this.props.type, data);
        }
    }

    setWindow = async (e, window) => {
        e.preventDefault();
        await this.setState({ window: window, page: 0 });
        this.getTrending(false);
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    onWeekDayChanged = async e => {
        let updated = {};
        updated[e.target.name] = e.target.checked;
        updated.page = 0;
        await this.setState(updated);
        this.getTrending(false);
    }

    render() {
        return (
            this.props.card
                ?
                <Card className={this.props.minimal ? "rounded-0 border-0 bg-transparent" : "rounded-0"}>
                    <CardHeader className={this.props.minimal ? "d-flex flex-row align-items-center text-dark pb-0 border-0 bg-transparent" : "d-flex flex-row align-items-center text-dark"}>
                        
                        <h5 className="h5 mb-0 cursor-pointer user-select-none" onClick={this.toggleCollapse }>{this.props.title} {this.props.showCount != null ? <Badge color="dark">{this.props.items.length}</Badge> : <span></span>}</h5>
                        {this.props.upcoming
                            ? null
                            :
                            <div className="ms-2">
                                <label className="toggle " data-on="Week" data-off="Day">
                                    <input type="checkbox" name="isWeek" checked={this.state.isWeek} onChange={this.onWeekDayChanged} />
                                    <span></span>
                                </label>
                                {/*({this.state.window === 'day' ? <span className="fw-semibold text-plex">Day</span> : <a href="#" className="link-secondary text-decoration-none" onClick={e => this.setWindow(e, 'day')}>Day</a>} */}
                                {/*&nbsp;|&nbsp; */}
                                {/*{this.state.window === 'week' ? <span className="fw-semibold text-plex">Week</span> : <a href="#" className="link-secondary text-decoration-none" onClick={e => this.setWindow(e, 'week')}>Week</a>})*/}
                            </div>
                        }
                        <div className={this.state.isOpen ? "ms-auto align-self-end" : "d-none"}>
                            <ButtonGroup>
                                <Button color="link" className="text-dark text-decoration-none pt-0"
                                    onClick={(event) => this.scrollTrendingItem(event, { container: `i-${this.state.id}`, direction: 'left' })}>
                                    <Icon.ChevronLeft />
                                </Button>
                                <Button color="link" className="text-dark text-decoration-none pt-0 pe-0"
                                    onClick={(event) => this.scrollTrendingItem(event, { container: `i-${this.state.id}`, direction: 'right' })}>
                                    <Icon.ChevronRight />
                                </Button>
                            </ButtonGroup>
                        </div>
                        
                    </CardHeader>
                    <Collapse isOpen={this.state.isOpen}>
                        {!this.state.items
                            ?
                            <CardBody className={this.props.minimal ? "bg-transparent" : ""}>
                                <Row className="mx-0">
                                    <Col className="text-center">
                                        <Spinner type="grow"> </Spinner>
                                    </Col>
                                </Row>
                            </CardBody>
                            :

                            <CardBody className={this.props.minimal ? "mx-0 pb-0 bg-transparent" : "mx-0 pb-0"}>
                                <Row id={`i-${this.state.id}`} className="flex-nowrap overflow-auto scroll scroll-plex">
                                    {this.state.items.map((t, index) =>
                                        <Col key={`trending-${t.id}`} xs="auto" className="mb-2">
                                            <Trending data={t} onClick={this.trendingClicked} />
                                        </Col>
                                    )}
                                    {this.state.hasMoreResults
                                        ? 
                                        <Col>
                                            <div style={{width: '75px'}} className="h-100">
                                                <Button color="link" className="text-decoration-none text-plex h-100 w-100"
                                                    onClick={this.loadMore}><Icon.ChevronRight /> Load More</Button>
                                            </div>
                                        </Col>
                                        : null
                                    }
                                </Row>
                            </CardBody>
                        }
                    </Collapse>
                    
                </Card>
                :
                <Row>
                    <Col>
                        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                            <span className="h5 mb-0">{this.props.title} {this.props.showCount != null ? <Badge color="dark">{this.props.items.length}</Badge> : <span></span>}</span>
                            <ButtonGroup>
                                <Button color="link" className="text-dark text-decoration-none pt-0" onClick={(event) => this.scrollTrendingItem(event, { container: `i-${this.state.id}`, direction: 'left' })}><Icon.ChevronLeft /></Button>
                                <Button color="link" className="text-dark text-decoration-none pt-0 pe-0" onClick={(event) => this.scrollTrendingItem(event, { container: `i-${this.state.id}`, direction: 'right' })}><Icon.ChevronRight /></Button>
                            </ButtonGroup>
                        </div>
                        <Row id={`i-${this.state.id}`} className="flex-nowrap overflow-auto scroll scroll-plex">
                            {this.props.items.map((t, index) =>
                                <Col key={t.id} xs="auto" className="mb-2">
                                    <Trending data={t} onClick={this.trendingClicked} />
                                </Col>

                            )}
                        </Row>
                    </Col>
                </Row>
        )
    }
}