import React, { Component } from 'react';
import {
    Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import * as Icon from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';

export class TVSubscriptions extends Component {
    static displayName = TVSubscriptions.name;

    constructor(props) {
        super(props);
        this.state = {
            sort: 'date.asc',
            subscriptions: []
        };
        this.unsubscribe.bind(this);
        this.getSubscriptions();
    }

    setSort = async (e, sort) => {
        e.preventDefault();

        await this.setState({
            sort: sort
        });
        await this.getSubscriptions();
    }

    unsubscribe = async (e, item) => {
        e.preventDefault();

        confirmAlert({
            title: 'Unsubscribe?',
            message: `Are you sure you want to unsubscribe from ${item.title}?`,
            buttons: [
                {
                    label: 'Yes!',
                    onClick: async () => {
                        try {
                            const response = await fetch(`/api/tmdb/tv/unsubscribe/${item.tmdbSeriesId}`, {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: {}
                            });
                            const data = await response.json();
                            if (data.success) {
                                toast.success(`You have successfully unsubscribed from ${item.title}.`);
                                this.getSubscriptions();

                            } else {
                                toast.error('An error occurred while unsubscribing!');
                            }
                        } catch (eror) {
                            toast.error('An error occurred while unsubscribing!');
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    getSubscriptions = async () => {
        let user = await authService.getUser();

        const response = await fetch(`/api/torrents/tv/subscriptions?sort=${this.state.sort}`);
        const result = await response.json();
        let items = result.map(item => {
            item.smallImage = `https://image.tmdb.org/t/p/w92${item.posterImage}`;
            item.largeImage = `https://image.tmdb.org/t/p/w185${item.posterImage}`;
            item.canDelete = item.userId === user.sub;
            item.addedBy = item.user.userName;
            return item;
        });
        await items.forEach(async item => {
            let seasonInfo = await this.getSeasonInfo(item.tmdbSeriesId, item.season);
            item.seasonInfo = seasonInfo;
        })
        
        await this.setState({
            subscriptions: items
        });
    }

    getSeasonInfo = async (id, season) => {
        const response = await fetch(`/api/tmdb/tv/${id}/seasons`);
        const data = await response.json();
        return data.seasons.filter(c => c.seasonNumber === season)[0];
    }

    render = () => (
        <div className="content">
            <div className="d-flex flex-row align-items-center mb-2">
                <div className="h4 text-plex mb-0">TV Subscriptions</div>
                <UncontrolledDropdown className="ms-auto">
                    <DropdownToggle color="link" className="p-0 d-flex align-items-center text-plex text-decoration-none"><Icon.ArrowDownUp className="me-1" /> Sort</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem active={this.state.sort === 'date.asc'} onClick={e => this.setSort(e, 'date.asc')}><Icon.SortDownAlt /> Next Episode</DropdownItem>
                        <DropdownItem active={this.state.sort === 'title.asc'} onClick={e => this.setSort(e, 'title.asc')}><Icon.SortAlphaDown /> Title</DropdownItem>
                        <DropdownItem active={this.state.sort === 'title.desc'} onClick={e => this.setSort(e, 'title.desc')}><Icon.SortAlphaDownAlt /> Title Descending</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
                <Table striped>
                <tbody>
                    {this.state.subscriptions
                        ?
                        this.state.subscriptions.map(item => {
                            
                            let url = `https://www.themoviedb.org/tv/${item.tmdbSeriesId}`;
                            return (
                                <tr key={`sub-${item.id}`}>
                                    <td>
                                        <img className="d-none d-md-block" src={item.largeImage} alt={item.displayName} />
                                        <img className="d-md-none" src={item.smallImage} alt={item.displayName} />
                                    </td>
                                    <td style={{ height: '1px' }}>
                                        <div className="d-flex flex-column">
                                            <div className="h4 mb-1">
                                                <a className="link-plex text-decoration-none" target="_blank" rel="noreferrer" href={url}>
                                                    {item.title}
                                                </a>
                                            </div>
                                            <div className="text-muted">{item.nextEpisodeNumber === -1 ? <small>Season {item.season} complete</small> : <small>Next Episode {item.nextEpisodeNumber} airs on {new Date(item.nextEpisodeAirDate).toLocaleDateString()}</small> }</div>
                                            <div className="text-muted">{item.lastEpisodeDownloadDate ? <small>Last Episode, Episode {item.lastEpisodeDownload} was downloaded on {new Date(item.lastEpisodeDownloadDate).toLocaleDateString()}</small> : null}</div>
                                            <div className="lead flex-fill">{item.overview}</div>
                                            <div className="flex-fill">
                                                {item.canDelete
                                                    ? <a href="#" className="link-plex text-decoration-none flex-shrink-1" onClick={e => this.unsubscribe(e, item)}>Unsubscribe</a>
                                                    : <span>Added by {item.addedBy}</span>}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        null
                    }
                </tbody>
            </Table>
        </div>
    )
}