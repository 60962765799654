import React, { Component } from "react";
import {
    Row, Col, Button, Input,
    FormGroup, Label,
} from 'reactstrap';
import { toast } from 'react-toastify';
import authService from "./api-authorization/AuthorizeService";

export class ViewSettings extends Component {
    static displayName = ViewSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            showTrendingTv: false,
            showTrendingMovies: false,
            showUpcomingMovies: false,
            showUpcomingTv: false
        }
        this.onCheckChange.bind(this);
    }

    async componentDidMount() {
        await this.getSettings();
    }

    onCheckChange = async (e) => {
        let updated = {};
        updated[e.target.name] = e.target.checked;
        this.setState(updated);
    }

    apply = async (e) => {
        await this.saveSettings();
    }

    restoreDefault = async (e) => {
        await this.setState({
            showTrendingTv: true,
            showTrendingMovies: true,
            showUpcomingMovies: true,
            showUpcomingTv: true
        });
        await this.saveSettings();
    }

    getSettings = async () => {
        let user = await authService.getUser();
        const response = await fetch(`/api/user/settings?userId=${user.sub}`);
        const result = await response.json();
        if (result.success) {
            await this.setState({
                showTrendingTv: result.data.showTrendingTv,
                showTrendingMovies: result.data.showTrendingMovies,
                showUpcomingMovies: result.data.showUpcomingMovies,
                showUpcomingTv: result.data.showUpcomingTv
            })
        } else {
            toast.error(result.message);
        }
    }

    saveSettings = async () => {
        let user = await authService.getUser();
        const response = await fetch(
            `/api/user/viewsettings?userid=${user.sub}&showtrendingtv=${this.state.showTrendingTv}&showtrendingmovies=${this.state.showTrendingMovies}&showupcomingtv=${this.state.showUpcomingTv}&showupcomingmovies=${this.state.showUpcomingMovies}`,
            {
                method: 'POST',
                header: { 'Content-Type': 'application/json' },
                body: {}
            });
        const data = await response.json();
        if (data.success) {
            toast.success('Successfully updated view settings');
            if (this.props.onSettingsUpdated) {
                this.props.onSettingsUpdated(this.state);
            }
        } else {
            toast.error(data.message);
        }
    }

    render = () => (
        <div>
            <Row className="g-2 me-1 align-items-center">
                <Col xs={12}>
                    <div className="fw-semibold mb-2">Home Page Settings</div>
                    <FormGroup check className="mb-2">
                        <Input name="showTrendingMovies" type="checkbox" checked={this.state.showTrendingMovies} onChange={this.onCheckChange} id="trendingmovie-check" />{' '}
                        <Label check for="trendingmovie-check" className="d-block">Show Trending Movies</Label>
                    </FormGroup>
                    <FormGroup check className="mb-2">
                        <Input name="showUpcomingMovies" type="checkbox" checked={this.state.showUpcomingMovies} onChange={this.onCheckChange} id="upcomingmovie-check" />{' '}
                        <Label check for="upcomingmovie-check" className="d-block">Show Upcoming Movies</Label>
                    </FormGroup>
                    <FormGroup check className="mb-2">
                        <Input name="showTrendingTv" type="checkbox" checked={this.state.showTrendingTv} onChange={this.onCheckChange} id="trendingtv-check" />{' '}
                        <Label check for="trendingtv-check" className="d-block">Show Trending TV</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input name="showUpcomingTv" type="checkbox" checked={this.state.showUpcomingTv} onChange={this.onCheckChange} id="upcomingtv-check" />{' '}
                        <Label check for="upcomingtv-check" className="d-block">Show Upcoming TV</Label>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <Button color="plex" onClick={this.apply}>Apply</Button>
                    <Button color="plex" outline onClick={this.restoreDefault} className="ms-2">Restore Default</Button>
                </Col>
            </Row>
        </div>
    )
}