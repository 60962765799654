import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
export class Layout extends Component {
  static displayName = Layout.name;

  render () {
        return (
        <div className="mb-3">
            <NavMenu />
            <Container fluid>
                    <ToastContainer position="top-center"
                        pauseOnHover={false}
                        closeOnClick
                        draggable={false}
                    />
                {this.props.children}
            </Container>
        </div>
        );
  }
}
