import React, { Component } from 'react';
import {
    Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Row, Col, Badge
} from 'reactstrap';
import * as Icon from 'react-bootstrap-icons';
import authService from './api-authorization/AuthorizeService';


export class Downloads extends Component {
    static displayName = Downloads.name;
    constructor(props) {
        super(props);
        this.state = {
            sort: 'date.desc',
            onlyMine: true,
            wishlist: []
        };
        
        this.getWishlist();
    }

    setSort = async (e, sort) => {
        e.preventDefault();

        await this.setState({
            sort: sort
        });
        await this.getWishlist();
    }

    onTypeChanged = async e => {
        e.preventDefault();
        await this.setState({
            onlyMine: !this.state.onlyMine
        });
        this.getWishlist();
    }

    getWishlist = async () => {
        let user = await authService.getUser();
        let url = this.state.onlyMine
            ? `/api/torrents/downloads?userid=${user.sub}&sort=${this.state.sort}`
            : `/api/torrents/downloads?userid=all&sort=${this.state.sort}`;
        const response = await fetch(url);
        const result = await response.json();
        let items = result.map(item => {
            let image = item.posterUrl?.substring(item.posterUrl.lastIndexOf('/'));
            item.smallImage = `https://image.tmdb.org/t/p/w92${image}`;
            item.largeImage = `https://image.tmdb.org/t/p/w185${image}`;
            item.dateDownloaded = new Date(item.dateDownloaded).toLocaleString();
            return item;
        })
        await this.setState({
            wishlist: items
        });
    }

    render = () => {
        return (
            <div className="content">
                <div className="d-flex flex-row align-items-center mb-2">
                    <div className="h4 text-plex mb-0">
                        Downloaded Content
                        <Badge pill color="plex" className="ms-2">
                            {this.state.wishlist.length}
                        </Badge> 
                    </div>
                    <UncontrolledDropdown className="ms-auto">
                        <DropdownToggle color="link" className="p-0 d-flex align-items-center text-plex text-decoration-none"><Icon.ArrowDownUp className="me-1" /> Sort</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem active={this.state.sort === 'date.asc'} onClick={e => this.setSort(e, 'date.asc')}><Icon.SortDownAlt /> Date Downloaded</DropdownItem>
                            <DropdownItem active={this.state.sort === 'date.desc'}  onClick={e => this.setSort(e, 'date.desc')}><Icon.SortDown /> Date Downloaded Descending</DropdownItem>
                            <DropdownItem active={this.state.sort === 'title.asc'}  onClick={e => this.setSort(e, 'title.asc')}><Icon.SortAlphaDown /> Title</DropdownItem>
                            <DropdownItem active={this.state.sort === 'title.desc'}  onClick={e => this.setSort(e, 'title.desc')}><Icon.SortAlphaDownAlt /> Title Descending</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <Row className="mb-2">
                    <Col xs="auto">
                        <div className="ms-2">
                            <label className="toggle " data-on="Mine" data-off="All">
                                <input type="checkbox" name="onlyMine" checked={this.state.onlyMine} onChange={this.onTypeChanged} />
                                <span></span>
                            </label>
                        </div>
                    </Col>
                </Row>
                <Table striped>
                    <tbody>
                        {this.state.wishlist
                            ?
                            this.state.wishlist.map(item => {

                                let url = `https://www.themoviedb.org/${item.season > 0 ? 'tv' : 'movie'}/${item.tmdbId}`;

                                return (
                                    <tr key={`dl-${item.id}`}>
                                        <td>
                                            <img className="d-none d-md-block" src={item.largeImage} alt={item.displayName} />
                                            <img className="d-md-none" src={item.smallImage} alt={item.displayName} />
                                        </td>
                                        <td>
                                            <div className="d-flex flex-column justify-content-between">
                                                <div className="h4 mb-1">
                                                    <a className="link-plex text-decoration-none" target="_blank" rel="noreferrer" href={url}>
                                                        {item.title}
                                                    </a>
                                                </div>
                                                <div>{item.season > 0 ? `Season ${item.season} Episode ${item.episode}` : null}</div>
                                                <div className="text-muted"><small>Downloaded on {item.dateDownloaded}</small></div>
                                                <p className="lead flex-fill">{item.overview}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            null
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}