import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Home } from "./components/Home";
import { Wishlist } from "./components/Wishlist";
import { Downloads } from "./components/Downloads";
import { TVSubscriptions } from './components/TVSubscriptions';
import { Logs } from './components/Logs';
import { PlexContent } from './components/PlexContent';

const AppRoutes = [
    {
        index: true,
        requireAuth: true,
        element : <Home />
    },
    {
        path: '/wishlist',
        requireAuth: true,
        element: <Wishlist />
    },
    {
        path: '/downloads',
        requireAuth: true,
        element: <Downloads />
    },
    {
        path: '/subscriptions',
        requireAuth: true,
        element: <TVSubscriptions />
    },
    {
        path: '/logs',
        requireAuth: true,
        element: <Logs />
    },
    {
        path: '/plexcontent',
        requireAuth: true,
        element: <PlexContent />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;