import React, { Component, Fragment } from 'react';
import {
    NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownItem,
    DropdownMenu

} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import * as Icon from 'react-bootstrap-icons';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            dropdownOpen: false,
            isAdmin: false
        };
        
    }

    checkIsAdmin = async () => {
        if (this.user) {
            const response = await fetch(`/api/user/isadmin?userid=${this.user?.sub}`);
            const data = await response.json();
            await this.setState({
                isAdmin: data.isAdmin
            });
        }
    }

    navLinkClicked = e => {
        //e.preventDefault();
        if (this.props.onNavLinkClicked) {
            this.props.onNavLinkClicked(e);
        }
    }

    toggle = async () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
        this.user = user;
        await this.checkIsAdmin();
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (isAuthenticated) {
            //const registerPath = `${ApplicationPaths.Register}`;
            //const loginPath = `${ApplicationPaths.Login}`;
            //return this.anonymousView(registerPath, loginPath);
            //} else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (
            <Fragment>
                <NavItem>
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/">
                        <Icon.Search className="fs-4 d-none d-sm-inline" />
                        <span className="d-sm-none">Search</span>
                    </NavLink>
                </NavItem>
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/downloads">Downloads</NavLink>
                </NavItem>
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/subscriptions">TV Subscriptions</NavLink>
                </NavItem>
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/wishlist">Wishlist</NavLink>
                </NavItem>
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/plexcontent">Plex Content</NavLink>
                </NavItem>
                {this.state.isAdmin
                    ?
                    <NavItem className="d-sm-none">
                        <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to="/logs">Logs</NavLink>
                    </NavItem>
                    : null
                }
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to={profilePath}>My Settings</NavLink>
                </NavItem>
                <NavItem className="d-sm-none">
                    <NavLink onClick={this.navLinkClicked} tag={Link} className="text-light" to={logoutPath} state={logoutState}>Logout</NavLink>
                </NavItem>
                <UncontrolledDropdown nav className="d-none d-sm-inline dropdown-menu-start">
                    <DropdownToggle nav className="text-light">
                        <Icon.PersonCircle className="fs-4" />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem tag={Link} color="dark" to="/downloads">Downloads</DropdownItem>
                        <DropdownItem tag={Link} color="dark" to="/plexcontent">Plex Content</DropdownItem>
                        <DropdownItem tag={Link} color="dark" to="/subscriptions">TV Subscriptions</DropdownItem>
                        <DropdownItem tag={Link} color="dark" to="/wishlist">Wishlist</DropdownItem>
                        <DropdownItem divider />
                        {this.state.isAdmin
                            ?
                            <React.Fragment>
                                <DropdownItem tag={Link} color="dark" to="/logs">Logs</DropdownItem>
                                <DropdownItem divider />
                            </React.Fragment>
                            : null
                        }
                        <DropdownItem tag={Link} color="dark" to={profilePath}>
                            My Profile
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} className="text-dark" to={logoutPath} state={logoutState}>
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Fragment>
        );
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-light" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-light" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
