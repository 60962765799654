import React, { Component } from 'react';
import {
    Button, Table, Row, Col, Label, FormGroup
} from 'reactstrap';
import authService from './api-authorization/AuthorizeService';

export class Logs extends Component {
    static displayName = Logs.name;

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            source: '',
            type: '',
            pageSize: 25,
            page: 1,
            totalRecords: 0,
            sources: [],
            logs: []
        };
    }

    async componentDidMount(){
        //let user = await authService.getUser();
        //if (!user.isAdmin) {

        //}
        this.getSources();
        await this.loadLogs();
    }

    valueChanged = async e => {
        let updated = {};
        updated[e.target.name] = e.target.value;
        updated.page = 1;
        await this.setState(updated);
        await this.loadLogs(false);
    }

    loadNextPage = async (e) => {
        e.preventDefault();
        await this.setState({
            page: this.state.page + 1
        });
        await this.loadLogs(true);
    }

    getSources = async () => {
        const response = await fetch('api/log/sources');
        const data = await response.json();
        await this.setState({
            sources: data.sources
        });
    }

    loadLogs = async (isAppend) => {
       const response =
            await fetch(`api/log/search?startdate=${this.state.startDate}&enddate=${this.state.endDate}&source=${this.state.source}&type=${this.state.type}&page=${this.state.page}&pagesize=${this.state.pageSize}`);
        const data = await response.json();
        await this.setState({
            totalRecords: data.totalRecords,
            logs: isAppend ? [...this.state.logs, ...data.logs] : data.logs
        });
    }

    render() {
        return (
            <div> 

                <Row className="mb-2">
                    <Col xs={6} md>
                        <FormGroup floating>
                            <input className="form-control" type="date" id="startDate" name="startDate" value={this.state.startDate} onChange={this.valueChanged} />
                            <Label for="startDate">Start Date</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={6} md>
                        <FormGroup floating>
                            <input className="form-control" type="date" id="endDate" name="endDate" value={this.state.endDate} onChange={this.valueChanged} />
                            <Label for="endDate">End Date</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={6} md>
                        <FormGroup floating>
                            <select className="form-control" name="source" value={this.state.source} onChange={this.valueChanged}>
                                <option value="">All</option>
                                {this.state.sources.map(s => (
                                    <option key={`source-${s}`} value={s}>{s}</option>
                                ))}
                            </select>
                            <Label>Source</Label>
                        </FormGroup>
                    </Col>
                    <Col xs={6} md>
                        <FormGroup floating>
                            <select className="form-control" name="type" value={this.state.type} onChange={this.valueChanged}>
                                <option value="">All</option>
                                <option value="DEBUG">DEBUG</option>
                                <option value="INFO">INFO</option>
                                <option value="WARNING">WARNING</option>
                                <option value="ERROR">ERROR</option>
                                <option value="CRITICAL">CRITICAL</option>
                            </select>
                            <Label>Type</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>Showing {this.state.logs.length} of {this.state.totalRecords} entries</Col>
                </Row>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th className="d-none d-sm-table-cell">Source</th>
                            <th>Type</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.logs.map(log => {
                            return (
                                <tr key={`logs-${log.id}`}>
                                    <td className="fst-italic fw-light">{new Date(log.timestamp).toLocaleString()}</td>
                                    <td className="fw-semibold d-none d-sm-table-cell">{log.source}</td>
                                    <td className={log.type === 'INFO' ? 'text-info' : log.type === 'WARNING' ? 'text-warning' : log.type === 'ERROR' || log.type === 'CRITICAL' ? 'text-danger' : null}>{log.type}</td>
                                    <td className="text-break" dangerouslySetInnerHTML={{__html: log.message} }></td>
                                </tr>
                            );
                        })
                        }
                        {this.state.totalRecords > this.state.logs.length
                            ? <tr><td colspan="4" className="text-center"><Button color="link" className="p-0 text-plex text-decoration-none" onClick={this.loadNextPage}>Load more...</Button></td></tr>
                            : null
                        }
                    </tbody>
                </Table>
            </div>
            
        )
    }

}